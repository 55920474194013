import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import ResizableArea from '../../components/ResizableArea/resizable-area';
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import * as styles from './011-radial-gauge.module.css';
import heroImg from '../../images/posts/011/radial-gauge-in-react.svg';
import MouseTrackFromCenter from '../../components/PostExtras/MouseTrackFromCenter/MouseTrackFromCenter';
import { RadialGaugeStep1Track } from '../../components/Chart';
import RadialGaugeAdjustDegrees from '../../components/PostExtras/RadialGauge/RadialGaugeAdjustDegrees';
import RadialGaugeStep2Drawing from '../../components/Chart/RadialGaugeSteps/RadialGaugeStep2Drawing';
import RadialGaugeAdjustValue from '../../components/PostExtras/RadialGauge/RadialGaugeAdjustValue';
import RadialGaugeStep3Interaction from '../../components/Chart/RadialGaugeSteps/RadialGaugeStep3Interaction';
import StrokeDashSvgExample from '../../components/PostExtras/RadialGauge/stroke-dash-svg-example';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeroPost gradientFrom="#667EEA" gradientTo="#764BA2" hasTiles={false} mdxType="HeroPost">
  <img src={heroImg} alt="Radial Gauge in React" height="306" width="365" />
    </HeroPost>
    <h1>{`Interactive Radial Gauge (in React)`}</h1>
    <Date date="June 12, 2023" dateMachine="2023-06-12" mdxType="Date" />
    <p>{`So you'd like to build an interactive radial gauge in React, huh? Pick any chart library and you are done for the static part 😉.
But if you'd like to dive a little bit deeper into this problem stay with me. The process of making it can show you an interesting perspective on
how things like that are done and what to consider when making it. `}<strong parentName="p">{`And of course, we will make it interactive!`}</strong>{` Chart libs would
most likely make it read-only.`}</p>
    <p>{`Below is what we are going to build. Imagine it's a component that lets you adjust light brightness in your room. Give it a try:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeAdjustValue showAdjuster={false} useFinal={true} mdxType="RadialGaugeAdjustValue" />
  </div>
    </div>
    <p>{`Did you use the setter ball to adjust the value?`}</p>
    <h2>{`First, there was a `}<del parentName="h2">{`light`}</del>{` track!`}</h2>
    <p>{`Actually, first we need to decide how we would like to render the gauge. Ideally, it should be responsive and configurable.
We could think of some circular `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` with a border that would work as a track but that seems to be pretty limited. We could
use a `}<inlineCode parentName="p">{`<canvas>`}</inlineCode>{` element which gives us freedom in what we render but it's not straightforward to get into interaction with canvas.
We also have to have in mind that the track is rounded and as you can see, it's not a `}<del parentName="p">{`perfect`}</del>{` full circle. It's a part of it.
It seems that the first important decision has to happen at the beginning. So...`}</p>
    <p><strong parentName="p">{`Let's use SVG!`}</strong></p>
    <p>{`Here we have a simple SVG on a `}<inlineCode parentName="p">{`300x300px`}</inlineCode>{` view box with a circle and border around (stroke):`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep1Track mdxType="RadialGaugeStep1Track" />
  </div>
    </div>
    <p>{`We place the center of the circle in the center of our box (150x150px), give it a radius (130px), and style the stroke.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';

export default function RadialGauge() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
      <circle
        fill="none"
        cx="150"
        cy="150"
        r="130"
        stroke="white"
        strokeWidth="20"
      />
    </svg>
  );
}
`}</code></pre>
    <h2>{`Make it responsive`}</h2>
    <p>{`There's a reason why "mobile first" was a popular saying a few years ago. Betting on responsiveness from the beginning sets our minds
in the proper mode. In our case, we will think in terms of equations, not some magic numbers. We actually have one already! 130 pixels
for the radius is the result of finding the center of the 300x300 box and making sure that stroke (20px) won't be cut.`}</p>
    <p>{`Let's change our code and set the `}<inlineCode parentName="p">{`viewBox`}</inlineCode>{` size to 100. Almost everything else will be in relation to that number.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{3-4,7-8,11,14-16,18}",
        "{3-4,7-8,11,14-16,18}": true
      }}>{`import React from 'react';

const TRACK_WIDTH_PX = 7;
const VIEW_BOX_SIZE_PX = 100;

export default function RadialGauge() {
  const viewBox = \`0 0 \${VIEW_BOX_SIZE_PX} \${VIEW_BOX_SIZE_PX}\`;
  const radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      <circle
        fill="none"
        cx="50%"
        cy="50%"
        r={radius}
        stroke="white"
        strokeWidth={TRACK_WIDTH_PX}
      />
    </svg>
  );
}
`}</code></pre>
    <p>{`Maybe I should have highlighted what didn't change... but anyway. Everything now is recalculated based on the size of the container.
Play with the resizer if you want:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <ResizableArea mdxType="ResizableArea">
      <RadialGaugeStep1Track mdxType="RadialGaugeStep1Track" />
    </ResizableArea>
  </div>
    </div>
    <h2>{`Track is not a full circle`}</h2>
    <p>{`Here's the first problem that can't be solved with regular DOM elements I think. We should rather think about our track
as `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` than a `}<inlineCode parentName="p">{`<circle>`}</inlineCode>{`. I'm not an SVG expert but I do like tricks so I'm going to show you one of the tricks.
Instead of drawing a path, we will use `}<inlineCode parentName="p">{`strokeDasharray`}</inlineCode>{` and `}<inlineCode parentName="p">{`strokeDashoffset`}</inlineCode>{` attributes of the circle.`}</p>
    <p>{`Those attributes are responsible for stroke dash. Here's an example code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg viewBox="0 0 30 4" xmlns="http://www.w3.org/2000/svg">
  <!-- No dashes nor gaps -->
  <line x1="0" y1="1" x2="30" y2="1" stroke="#2a2a2a" />

  <!-- Dashes and gaps of the same size -->
  <line x1="0" y1="3" x2="30" y2="3" stroke="#2a2a2a" stroke-dasharray="4" />
</svg>
`}</code></pre>
    <p>{`As a result, we should expect a solid line and a line with gaps:`}</p>
    <div className="container">
  <StrokeDashSvgExample mdxType="StrokeDashSvgExample" />
    </div>
    <p>{`How is that going to help us? Well, we will draw just one dash with a gap:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep1Track step={2} mdxType="RadialGaugeStep1Track" />
  </div>
    </div>
    <p>{`To do it we need to know the circumference of the track - this is our dash. And we will put a gap for X percent of the circumference.
At this point, we have to decide how much space the track should occupy. I'm not sure how that would be solved with `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` but
with a circle, we can think in terms of angles/degrees. That's a good mental model to have I think. Here we use 270°.`}</p>
    <p>{`Code changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{3,11-14,24-25}",
        "{3,11-14,24-25}": true
      }}>{`import React from 'react';

const TRACK_SIZE_DEGREES = 270;
const TRACK_WIDTH_PX = 7;
const VIEW_BOX_SIZE_PX = 100;

export default function RadialGauge() {
  const viewBox = \`0 0 \${VIEW_BOX_SIZE_PX} \${VIEW_BOX_SIZE_PX}\`;
  const radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2;

  const circumference = 2 * Math.PI * radius;
  const dasharray = circumference;
  const trackFillPercentage = TRACK_SIZE_DEGREES / 360;
  const trackDashoffset = circumference * (1 - trackFillPercentage);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      <circle
        fill="none"
        cx="50%"
        cy="50%"
        r={radius}
        stroke="white"
        strokeDasharray={dasharray}
        strokeDashoffset={trackDashoffset}
        strokeWidth={TRACK_WIDTH_PX}
      />
    </svg>
  );
}
`}</code></pre>
    <p>{`It doesn't look like it's properly positioned yet, right? And our reference doesn't have hard stops at the ends. Fortunately,
SVG has a `}<inlineCode parentName="p">{`stroke-linecap: round`}</inlineCode>{` for the ends problem and `}<inlineCode parentName="p">{`transform`}</inlineCode>{` to rotate the gauge. We need to remember that things are relative
and configurable - through constants now, but still configurable. Here's the result:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep1Track step={3} mdxType="RadialGaugeStep1Track" />
  </div>
    </div>
    <p>{`There's a bit of math involved unfortunately:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const cxy = VIEW_BOX_SIZE_PX * 0.5;
const trackTransform = \`rotate(\${
  -(TRACK_SIZE_DEGREES / 2) - 90
}, \${cxy}, \${cxy})\`;
`}</code></pre>
    <p>{`So far we were getting away from calculating the center of the circle by using `}<inlineCode parentName="p">{`50%`}</inlineCode>{` for cx and cy but now we have to calculate that.
When you rotate anything in space you have to specify a point around which the thing is rotated. Think about rotating the Earth. Rotating it around
its center will result in Earth spinning (nights and days). Rotating it around the center of our solar system (sun) will cause a very
different move (and result in seasons).`}</p>
    <p>{`When doing the rotation we somehow have to place the center of the gap at the bottom center of the "canvas". We can do it with the equation
presented above.`}</p>
    <p>{`You can play with the track size `}{`[in degrees]`}{` below to see how well it positions itself and how the dash and gap play together:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeAdjustDegrees mdxType="RadialGaugeAdjustDegrees" />
  </div>
    </div>
    <p>{`Very satisfying indeed!`}</p>
    <h2>{`Another track with value`}</h2>
    <p>{`Now it's time to handle the value. We will draw another track on top of the existing one so it will look filled.
You might not believe it but the only thing that we need to change is `}<inlineCode parentName="p">{`stroke-dashoffset`}</inlineCode>{` and calculating it is not that difficult.
Let's introduce a prop `}<inlineCode parentName="p">{`value`}</inlineCode>{` with some default and calculate the missing variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{2,6,8-9,24-35}",
        "{2,6,8-9,24-35}": true
      }}>{`// [...]
const INITIAL_VALUE_PERCENTAGE = 0;
const TRACK_SIZE_DEGREES = 270;
// [...]

export default function RadialGauge({ value }) {
  // [...]
  const valuePercentage = (value / 100) * trackFillPercentage;
  const valueDashoffset = circumference * (1 - valuePercentage);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      <circle
        fill="none"
        cx="50%"
        cy="50%"
        r={radius}
        stroke="white"
        strokeDasharray={dasharray}
        strokeDashoffset={trackDashoffset}
        strokeWidth={TRACK_WIDTH_PX}
      />

      <circle
        fill="none"
        strokeLinecap="round"
        cx="50%"
        cy="50%"
        r={radius}
        stroke="gold"
        strokeDasharray={dasharray}
        strokeDashoffset={valueDashoffset}
        strokeWidth={TRACK_WIDTH_PX}
        transform={trackTransform}
      />
    </svg>
  );
}
`}</code></pre>
    <p>{`Tada 🎉 We have the value visualized with a gold track. It's 50% currently.`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep2Drawing value={50} mdxType="RadialGaugeStep2Drawing" />
  </div>
    </div>
    <p>{`To make it a little bit nicer I'll put some text on the gauge that is going to show the value in a written form and
add some colors, like a little gradient to the gold track. But that's purely aesthetics so we won't focus too much on it.`}</p>
    <p>{`Please play with the range slider below:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeAdjustValue mdxType="RadialGaugeAdjustValue" />
  </div>
    </div>
    <p>{`Things are getting real, aren't they?`}</p>
    <h2>{`Make it interactive`}</h2>
    <h3>{`Draw the handler`}</h3>
    <p>{`Controlling the value from the outside is cool, but even cooler is to make the gauge interactive and put some kind of handle
on it so a user can easily move the handle and set the new value.`}</p>
    <p>{`Let's put a ball-like handler/setter in the neutral position (50%). With the center point being 50%, 50% as for other elements,
we need to translate it to the top - `}<inlineCode parentName="p">{`translate(0, -46)`}</inlineCode>{`. So we just moved it 46 pixels up.`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep2Drawing step={3} value={50} mdxType="RadialGaugeStep2Drawing" />
  </div>
    </div>
    <p>{`Of course, that's a magic number ✨ and other elements are not aware of the extra space we need for the setter to avoid it
being cut on the edges.`}</p>
    <p>{`Here are the necessary adjustments. I'm going to bind the setter's radius in relation to the stroke of tracks. It's going to be 80%
of the track's width + a border of 33%. Then we adjust the radius to take that into account. This time, magic numbers are allowed 😅
I'm picking proportions that just look good on the screen to me and have the potential to scale nicely.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{2-3,8-10}",
        "{2-3,8-10}": true
      }}>{`// [...]
const SETTER_RADIUS_RATIO = 0.8;
const SETTER_STROKE_RATIO = 1 / 3;
const VIEW_BOX_SIZE_PX = 100;

export default function RadialGauge({ value }) {
  const viewBox = \`0 0 \${VIEW_BOX_SIZE_PX} \${VIEW_BOX_SIZE_PX}\`;
  const setterRadius = TRACK_WIDTH_PX * SETTER_RADIUS_RATIO;
  const setterStrokeWidth = TRACK_WIDTH_PX * SETTER_STROKE_RATIO;
  const radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2 - setterRadius;

  // [...]
}
`}</code></pre>
    <p>{`It's not cut anymore, but I again adjusted the top `}<inlineCode parentName="p">{`translate`}</inlineCode>{` value manually to get the desired effect.`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep2Drawing step={4} value={50} mdxType="RadialGaugeStep2Drawing" />
  </div>
    </div>
    <p>{`To position it properly and react to the value, we will again have to do a bit of math.`}</p>
    <p>{`First of all, `}<strong parentName="p">{`the setter moves on an arc`}</strong>{`. To properly calculate the position of the setter we use equations with sinus and cosines `}{`[angles are in radians]`}{`:`}</p>
    <ul>
      <li parentName="ul">{`x = - radius `}{`*`}{` sin(angle)`}</li>
      <li parentName="ul">{`y = radius `}{`*`}{` cos(angle)`}</li>
    </ul>
    <p>{`Ha! You thought you don't need sinus and cosines anymore you nasty ≈ Tailwind-kid, huh?! 😉`}</p>
    <p>{`Final equations are a bit more complicated because of the track size property that determines how many "angles" are 100% of the track
(full circle = 360°). We need to take a proportion and adjust the angle with half of the part of the circle that "remains".
Maybe it's easier to show the code than reading the plain text?`}</p>
    <p>{`So we use some helper functions to calculate the x and y of the setter and apply it as `}<inlineCode parentName="p">{`transform`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{3-4,15}",
        "{3-4,15}": true
      }}>{`export default function RadialGauge({ value }) {
  // [...]
  const setterPos = calculateSetterPosition(radius, TRACK_SIZE_DEGREES, value);
  const setterTranslate = \`translate(\${setterPos.x}, \${setterPos.y})\`;
  // [...]
  return (
    // [...]
      <circle
        fill="#ffc400"
        stroke="#fff"
        cx="50%"
        cy="50%"
        r={setterRadius}
        strokeWidth={setterStrokeWidth}
        transform={setterTranslate}
      />
    </svg>
  );
}
`}</code></pre>
    <p>{`The helper functions are (1) the hearth of the implementation -> angular offset and (2) conversion from degrees to radians
that the first function uses internally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// (1)
export function calculateSetterPosition(radius, size, value) {
  return {
    x: -radius * Math.sin(degToRad((value / 100) * size + (360 - size) / 2)),
    y: radius * Math.cos(degToRad((value / 100) * size + (360 - size) / 2)),
  };
}

// (2)
export function degToRad(deg) {
  return deg * (Math.PI / 180);
}
`}</code></pre>
    <p>{`Let's see the effect. Play with the value slider and see how nicely it fits the track:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeAdjustValue step={5} mdxType="RadialGaugeAdjustValue" />
  </div>
    </div>
    <h3>{`Grab and move the handler`}</h3>
    <p>{`Now we have something to drag. I'm an old-school kid so we will use `}<inlineCode parentName="p">{`mousedown`}</inlineCode>{`, `}<inlineCode parentName="p">{`mousemove`}</inlineCode>{`, and `}<inlineCode parentName="p">{`mouseup`}</inlineCode>{` events.`}</p>
    <p>{`First, we will put it into a moving state by handling `}<inlineCode parentName="p">{`mousedown`}</inlineCode>{`, when the user clicks on the setter. Then we have to "guess"
where to put the setter on the track by calculating the trajectory - turning the mouse's x,y into x,y on the track.
Here's a small visualization:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <MouseTrackFromCenter mdxType="MouseTrackFromCenter">
    <div className={styles.gaugeSize}>
      <RadialGaugeStep3Interaction value={50} step={2} showText={false} mdxType="RadialGaugeStep3Interaction" />
    </div>
  </MouseTrackFromCenter>
    </div>
    <p>{`When you move around, the line that starts in the center crosses the track. There's also a rare case (for our
270° arc) where the line doesn't meet the arc - we will have to apply a limit there so a user can't move the setter outside
of the track.`}</p>
    <p>{`Once again, we need math. But instead of calculating the trajectory, we will try to kill two birds 🐦🐦 with one stone 🪨.
We are not interested in pixel precision. We don't want to set 66.781%. No. All we want is to snap the values to
decimal numbers - 66% or 67% in the example above.`}</p>
    <p>{`So we will have to calculate the closest allowed value anyway for snapping to work. Maybe let's find out what is the closest point to the user's mouse position?
As a mental exercise, let's put all values (0%, 1%, 2%, ..., 99%, 100%) on the track and show which one is the closest
to the mouse.`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeStep3Interaction value={50} step={3} showText={false} showBalls={true} mdxType="RadialGaugeStep3Interaction" />
  </div>
    </div>
    <p>{`As you move your mouse over you should see one circle being bigger than others - this is what we've just calculated
as the closest point to the user's mouse position.`}</p>
    <p><strong parentName="p">{`How is that done?`}</strong></p>
    <p>{`First, we need to use a method already known to us - `}<inlineCode parentName="p">{`calculateSetterPosition`}</inlineCode>{`. But this time, we will iterate through
all possible values between 0 and 100 to calculate all possible positions for the setter. Let's call it `}<inlineCode parentName="p">{`stepsCoords`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export function calculateStepsCoords(radius, size) {
  const stepsCoords = [];
  for (let i = 0; i <= 100; i++) {
    const coords = calculateSetterPosition(radius, size, i);

    stepsCoords.push({
      value: i,
      x: coords.x,
      y: coords.y,
    });
  }
  return stepsCoords;
}
`}</code></pre>
    <p>{`Then when we move the mouse inside the container, we need to find the closest point in `}<inlineCode parentName="p">{`stepsCoords`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// [...]
const [currentStep, setCurrentStep] = useState(null);

// [...]
function onMove(event) {
  const cxy = event.currentTarget.clientWidth / 2;
  const x = event.pageX - event.currentTarget.offsetLeft - cxy;
  const y = event.pageY - event.currentTarget.offsetTop - cxy;

  const closestStep = findClosestStep(stepsCoords, x, y);

  if (currentStep?.value !== closestStep.value) {
    setCurrentStep(closestStep);
  }
}

// [...]
export function findClosestStep(stepsCoords, x, y) {
  let min = distanceBetweenPoints({ x, y }, stepsCoords[0]);
  let minStep = stepsCoords[0];

  stepsCoords.forEach((step) => {
    const distance = distanceBetweenPoints({ x, y }, step);

    if (min > distance) {
      min = distance;
      minStep = step;
    }
  });

  return minStep;
}

// [...]
export function distanceBetweenPoints(A, B) {
  return Math.hypot(B.x - A.x, B.y - A.y);
}
`}</code></pre>
    <p>{`Despite it might look complicated there are just 3 steps here:`}</p>
    <ul>
      <li parentName="ul">{`Handling "on move" and calculating mouse position relative to the center of the canvas`}</li>
      <li parentName="ul">{`Iterating through all the points and calculating the distance to the mouse through `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/hypot"
        }}>{`Math.hypot()`}</a></li>
      <li parentName="ul">{`Saving the point for which the distance is the shortest`}</li>
    </ul>
    <p>{`In the explanation above we were saving the "current step" where in fact we just want to set the value. Following
the good pattern that was coined by EmberJS -> Action up, Data down, we will just call a function prop and let
the outside world control the gauge.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{8-10}",
        "{8-10}": true
      }}>{`function onMove(event) {
  const cxy = event.currentTarget.clientWidth / 2;
  const x = event.pageX - event.currentTarget.offsetLeft - cxy;
  const y = event.pageY - event.currentTarget.offsetTop - cxy;

  const closestStep = findClosestStep(stepsCoords, x, y);

  if (closestStep.value !== value && typeof onValueChange === 'function') {
    onValueChange(closestStep.value);
  }
}

// In JSX
const [value, setValue] = useState(50);

<RadialGauge value={value} onValueChange={setValue} />;
`}</code></pre>
    <p>{`And that's basically all. We get the final result:`}</p>
    <div className={['container', styles.gaugeBackground].join(' ')}>
  <div className={styles.gaugeSize}>
    <RadialGaugeAdjustValue showTrackSizeAdjuster={true} useFinal={true} mdxType="RadialGaugeAdjustValue" />
  </div>
    </div>
    <h2>{`Bonus. Support touchscreen`}</h2>
    <p>{`That should be treated as something normal, not a bonus, right? 😅 Supporting a touchscreen for this type of interaction
is pretty easy.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`onMove`}</inlineCode>{` function has to check if touch is not involved and if it is, then we focus only on the first touch:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{3,7-8}",
        "{3,7-8}": true
      }}>{`function onMove(event) {
  if (isSetterMoving) {
    const isTouch = event.touches && event.touches[0];

    const cxy = event.currentTarget.clientWidth / 2;

    const pageX = isTouch ? event.touches[0].pageX : event.pageX;
    const pageY = isTouch ? event.touches[0].pageY : event.pageY;
    const x = pageX - event.currentTarget.offsetLeft - cxy;
    const y = pageY - event.currentTarget.offsetTop - cxy;

    const closestStep = findClosestStep(stepsCoords, x, y);

    if (closestStep.value !== value && typeof onValueChange === 'function') {
      onValueChange(closestStep.value);
    }
  }
}
`}</code></pre>
    <p>{`Of course, we also have to enrich our markup with proper event listeners:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{5-6,20}",
        "{5-6,20}": true
      }}>{`<div
  className={styles.gaugeContainer}
  onMouseUp={() => setSetterMoving(false)}
  onMouseMove={(ev) => onMove(ev)}
  onTouchEnd={() => setSetterMoving(false)}
  onTouchMove={(ev) => onMove(ev)}
>
  // [...] // Setter
  <circle
    className={styles.gaugeSetter}
    fill="#ffc400"
    stroke="#fff"
    cx="50%"
    cy="50%"
    r={setterRadius}
    strokeWidth={setterStrokeWidth}
    transform={setterTranslate}
    onMouseDown={() => setSetterMoving(true)}
    onTouchStart={() => setSetterMoving(true)}
  />
  // [...]
</div>
`}</code></pre>
    <p>{`The demo above, and at the beginning of this post already includes the touchscreen support.`}</p>
    <h2>{`Challenge me! Please 🙏`}</h2>
    <p>{`Did you see a concept of a non-trivial interface somewhere? Maybe I could try to implement it and describe the process
here? Please DM me on Twitter or reply to this post's tweet.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1668164408890064896" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Sorting colors in JavaScript" prevLink="/posts/sorting-colors-in-js" nextTitle="Benchmarking GraphQL solutions in the JS/TS landscape" nextLink="/posts/benchmarking-graphql-solutions-in-the-js-ts-landscape" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      